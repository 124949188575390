import {
  title,
  subtitle,
  whiteColor,
  grayColor,
  card
} from 'assets/jss/styles.js'

const sectionPriceExampleStyle = {
  title,
  subtitle: {
    ...subtitle,
    fontSize: "1.5rem"
  },
  card: {
    ...card,
    padding: "25px",
    textAlign: "center",
    "& hr": {
      margin: "50px 0"
    }
  },
  priceElement: {
    padding: "15px",
  },
  price: {
    fontWeight: "bold",
    fontSize: "1.5rem"
  }
}

export default sectionPriceExampleStyle