import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import style from 'assets/jss/styles/pages/sectionPriceExampleStyle.js'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(style)

const SectionPriceExample = ({ title, subtitle, priceText, keyword, price, maxPrice, minPrice, content }) => {
	const classes = useStyles()
	return (
		<section className={classes.card} itemScope itemType="http://schema.org/Product">
			<h2 className={classes.title} itemProp="name">{title}</h2>
			<h3 className={classes.subtitle}>{subtitle}</h3>
			<div itemProp="offers" itemScope itemType="http://schema.org/Offer">
				<meta itemProp="priceCurrency" content="EUR" />
				<div itemProp="priceSpecification" itemScope itemType="http://schema.org/PriceSpecification">
					<meta itemProp="price" content={price} />
					<meta itemProp="priceCurrency" content="EUR" />
					<meta itemProp="maxPrice" content={maxPrice} />
					<meta itemProp="minPrice" content={minPrice} />
				</div>
				<hr />
				<div className={classes.priceElement}>
					<meta itemProp="name" content={`Precio de ${keyword} en Barcelona`} />
					<p>{priceText}</p>
					<span className={classes.price}>{price} €</span>
				</div>		
				<hr />	
			</div>
			<p>* Estos precios son una estimación. El precio final dependerá de factores como las calidades de los materiales o las dimensiones.</p>
			<p>El precio suele oscilar entre {minPrice} € y {maxPrice} €</p>
			<p><b>Solicita un <Link to="/presupuesto" title={`Presupuesto ${keyword} Barcelona`}>presupuesto personalizado</Link></b></p>
		</section>
	)
}

SectionPriceExample.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	price: PropTypes.string,
	priceText: PropTypes.string,
	keyword: PropTypes.string,
	maxPrice: PropTypes.string,
	minPrice: PropTypes.string,
	content: PropTypes.node
}

export default SectionPriceExample
